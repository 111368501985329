<template>
  <div class="wbobyy">
    <el-date-picker
      v-model="value2"
      type="daterange"
      align="right"
      unlink-panels
      :range-separator="$t('All.至')"
      :start-placeholder="$t('All.开始日期')"
      :end-placeholder="$t('All.结束日期')"
      :picker-options="pickerOptions"
      format="yyyy 年 MM 月 dd 日">
    </el-date-picker>
    <el-button style="width: 200px;margin-top: 30px;margin-left: 75px;" type="primary" @click="GetScheduling">{{ $t('All.查询并导出') }}</el-button>
    <download-excel id="download_excel" class = "export-excel-wrapper" :data = "excelpage" :fields = "json_fields" :before-generate="beforegenerate" :before-finish="beforefinish" name = "Scheduling.xls">
      <el-button v-show="false" type="primary" icon="download" > Download </el-button>
    </download-excel>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: this.$t('All.最近一周'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近一个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近三个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      value1: '',
      value2: '',
      excelpage: [], // 存放用于导出excel的数据
      json_fields: {
        完成时间: 'Date', // 常规字段
        预计完成日期: 'Estimate', // 支持嵌套属性
        通知单号: 'Numbers',
        送测部门: 'Department',
        客户厂商: 'Customer_manufacturer',
        品名料号: 'Product_item',
        实验名称: 'Experiment_name',
        申请人姓名: 'Applicantname',
        接单人姓名: 'Receivername',
        实验结果: 'Results'
      },
      json_meta: [
        [
          {
            ' key ': ' charset ',
            ' value ': ' utf- 8 '
          }
        ]
      ]
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    async GetScheduling () {
      const postdata = {
        UserData: this.$store.state.Login,
        Start: this.value2[0],
        End: this.value2[1]
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/GetScheduling', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.$message.success(this.$t('All.数据不存在'))
          return
        }
        this.excelpage = res.response
        document.getElementById('download_excel').click()
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    beforegenerate () {
      console.log(this.$t('All.开始下载'))
    },
    beforefinish () {
      console.log(this.$t('All.下载完成'))
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 0px;
  width: 300px;
}
</style>
